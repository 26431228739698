import Vue from 'vue'
import {mapGetters} from 'vuex'

export default Vue.extend({
  methods: {
    setupLayout() {
      this.$store.dispatch('base/setupLayoutName', this.layoutName)
    },
  },
  computed: {
    ...mapGetters({
      layoutReady: 'base/layoutReady',
      siteLogoUrl: 'base/siteLogoUrl',
      siteName: 'base/siteName',
      isLogin: 'token/isLogin',
    }),
  },
})